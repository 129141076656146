import * as React from 'react';
import Layout from '../components/layout';
import SEO, { SeoModel } from '../components/seo';
import VacanciesList from '../components/vacancies-list';

interface VacanciesPageProps {
  location: Location,
}

export default class VacanciesPage extends React.Component<VacanciesPageProps> {

  public render() {

    const seo: SeoModel = {
      title: 'Vacatures'
    };

    return (
      <Layout location={this.props.location}>
        <SEO model={seo} />
        <div className="mt-5" />
        <section id="events" className={`bg-white py-5`}>
          <h1 className="my-5 text-center">Vacatures</h1>
          <div className="container-lg">
            <VacanciesList />
          </div>
        </section>
      </Layout>
    );
  }
}

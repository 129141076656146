import { useStaticQuery, graphql } from 'gatsby';
import { VacancyNode } from '../components/vacancies-list';

export const querySharePointVacatures = (): ({ node: VacancyNode }[]) => {
  const { allOrganisatieVacaturesListItem } = useStaticQuery(
    graphql`
    {
      allOrganisatieVacaturesListItem(filter: {data: {fields: {Gepubliceerd_x003f_: {eq: true}}}}) {
        edges {
          node {
            data {
              fields {
                Title
                Status
                Functiebeschrijving: field_1
                Doelgroep: field_2
                Advertentiegroepen: field_4
                Advertentiekanalen: field_5
                Gepubliceerd_x003f_
              }
              id
            }
          }
        }
        totalCount
      }
    }
    `
  )
  return allOrganisatieVacaturesListItem.edges
}
import * as React from 'react';
import styles from './vacancies-list.module.scss';
import { querySharePointVacatures } from '../queries/sharepointVacancies';


export interface VacancyNode {
  data: {
    fields: {
      id: string,
      Title: string,
      Status: string,
      Functiebeschrijving: string,
      Doelgroep: string,
      Advertentiegroepen: string,
      Advertentiekanalen: string,
      Gepubliceerd_x003f_: boolean,
    }
  }
}

interface VacancyListProps {
}

const VacanciesList: React.SFC<VacancyListProps> = ({
}) => {
  const listItems: ({ node: VacancyNode }[]) = querySharePointVacatures().filter(x => x.node.data.fields.Gepubliceerd_x003f_);
  const vacatureItems = listItems.map(x => x.node.data);

  return (
    <div>
      <h4 className="text-center text-muted">Momenteel {vacatureItems.length || 'geen'} openstaande vacatures
      </h4>
      <p className='mb-4 text-center text-muted'>
        <small>Deze informatie is onderhevig aan wijzigingen.</small>
      </p>
      <p className="text-justify"><strong>Wij zoeken jou!</strong> Want met onze ambitie en drive zijn extra handen altijd meer dan welkom. Praktisch ingesteld, analytisch, creatief of nog anders: jouw bijdrage is ontzettend waardevol. Op deze pagina beschrijven we enkele vacatures die op dit moment relevant zijn en prioriteit krijgen, maar we kunnen hier niet alles kwijt. <strong>Ook elke open sollicitatie ontvangen we met open armen.</strong></p>
      <p className="text-justify"> Als vrijwilliger bij Jubal krijg je de ruimte om een betekenisvolle bijdrage te leveren aan het verenigingsleven, de entertainment die we creëren en de ontwikkeling van onze leden en staff. Zo bouw je mee aan de rijke historie van deze vereniging, sinds 1911.</p>
      <p  className="text-justify">Vrijwilligerswerk doe je bovendien ook voor jezelf. Jij krijgt de ruimte om te groeien bij Jubal, op persoonlijk vlak en in je carrière. Naast een gezonde vrijheid en voldoende uitdagingen om de tanden in te zetten, kent de vereniging een goudmijn aan expertise op de meest uiteenlopende vlakken. Wij geloven dat de ervaring die je opdoet en alles dat je met en van deze oranje familie kan leren, ook jou zal verderbrengen.</p>
      <p  className="mb-4 text-justify"><strong>Laten we in gesprek gaan.</strong><br />Welkom bij de club!</p>
      <hr className="mb-5" />
      {vacatureItems.map(data => {
        return (
          <div key={`event-${data.fields.id}`} className={`row ${styles.rowStriped} d-flex flex-nowrap deco-none`}>
            <div className="flex-grow-1 my-3 px-3 text-justify">
              <h3 className="mb-0 text-uppercase"><strong>{data.fields.Title}</strong></h3>
              <h5 className="mt-0 text-uppercase text-muted font-weight-light"></h5>
              <p>{data.fields.Functiebeschrijving}</p>
              <p className='text-muted'>{data.fields.Doelgroep}</p>
              <a href={`mailto:bestuur@jubal.org?subject="Vacature ${data.fields.Title}"`}>Ik wil meer weten of mij aanmelden voor deze vacature.</a>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default VacanciesList;
